import * as React from 'react';
import { IProfileDetailsResponse } from '../../../../types/profile.types';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import TabPane from 'antd/es/tabs/TabPane';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import FulfillmentSettings from './fulfillment-settings';
import ChannelSettings from './channel-settings';
import { Tabs } from 'antd';

interface INebulaConfigurationProps {
  profileDetails: IProfileDetailsResponse;
}

const NebulaConfiguration: React.FunctionComponent<INebulaConfigurationProps> = ({ profileDetails }) => {
  const [configForm] = useForm();
  const { t } = useTranslation();
  const [tabKey, setTabKey] = React.useState<string>('1');

  React.useEffect(() => {
    populateConfigForm();
  }, [profileDetails]);

  const populateConfigForm = () => {
    const {
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      retry_intervals,
      cancel_order_for_inventory_shortage,
      constraints,
      inventory_control,
      optimization,
      priorities,
      reservations
    } = profileDetails?.tenant_details?.subscriptions?.fulfillment_settings || {};
    const { back_ordered_in_hrs } = retry_intervals || {};
    const {
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      fulfil_bundle_products_from_same_node
    } = constraints || {};

    const {
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales
    } = inventory_control || {};

    const { allow_item_reservation } = reservations;
    const {
      by_delivery_date,
      by_landed_cost,
      by_number_of_shipments,
      by_priority,
      by_ship_to_distance,
      show_courier_option,
      max_sourcing_dg_group,
      check_availability_first
    } = optimization;
    const {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based
    } = priorities;
    const formValues = {
      distance_weightage_factor,
      node_priority_based,
      node_priority_factor_multiplier,
      node_weightage_factor,
      ship_to_distance_based,
      by_delivery_date,
      by_landed_cost,
      by_number_of_shipments,
      by_priority,
      by_ship_to_distance,
      allow_item_reservation,
      apply_future_safety_factor,
      apply_onhand_safety_factor,
      consider_future_inventory_inquiry,
      consider_future_inventory_sales,
      allow_max_shipment_delay_window,
      combine_with_future_inventory,
      item_line_ship_complete,
      item_line_ship_from_single_node,
      maximum_release_lead_days,
      ship_complete,
      ship_from_single_node,
      ship_when_they_are_available,
      back_ordered_in_hrs,
      cancel_order_for_inventory_shortage,
      ignore_shipment_consolidation,
      local_delivery_max_distance_radius,
      fulfil_bundle_products_from_same_node,
      show_courier_option,
      max_sourcing_dg_group,
      check_availability_first
    };

    configForm.setFieldsValue(formValues);
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <section>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={<BoldButtonLabel labelText={t('fulfillmentSettings')} />} key="1">
          <FulfillmentSettings active={tabKey === '1'} profileDetails={profileDetails} />
        </TabPane>
        <TabPane tab={<BoldButtonLabel labelText={t('channelSettings')} />} key="2">
          <ChannelSettings active={tabKey === '2'} profileDetails={profileDetails} />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default NebulaConfiguration;

import { COMMON_SERVICE_URI, LOGIN_URI, PROFILE_URI } from '../config/envConfig';
import httpClient from '../http/http.client';

export const getProfileDetails = () => {
  const url = `${PROFILE_URI}/profiles/v1/my`;
  return httpClient.get({ url });
};

export const getChannel = (params: any) => {
  const url = `${COMMON_SERVICE_URI}/v1/channels`;
  return httpClient.get({ url, params });
};

export const createChannel = (bodyData: any) => {
  const url = `${LOGIN_URI}/profiles/v1/channel`;
  return httpClient.post({ url, body: bodyData });
};

export const getChannelById = (id: string) => {
  const url = `${LOGIN_URI}/profiles/v1/channel/${id}`;
  return httpClient.get({ url });
};

export const editChannel = (body: any) => {
  const url = `${LOGIN_URI}/profiles/v1/channel`;
  return httpClient.patch({ url, body });
};

export const updateStatus = (body: any) => {
  const url = `${LOGIN_URI}/profiles/v1/channel/status`;
  return httpClient.patch({ url, body });
};

export const getUserList = (params: any) => {
  const url = `${PROFILE_URI}/identity/v1/users`;
  return httpClient.get({ url, params });
};

export const editUserAddress = (request: any) => {
  const url = `${PROFILE_URI}/contacts/v1`;
  return httpClient.put({ url, body: request });
};

export const getTenantSecret = ({ businessGroupId, tenantId }: { tenantId: string; businessGroupId: string }) => {
  const url = `${PROFILE_URI}/identity/v1/client`;

  const headers = {
    'x-tenant-id': tenantId,
    'x-business-group-id': businessGroupId
  };
  return httpClient.get({ url, headers });
};

export const addNewUser = (bodyData: any) => {
  const url = `${PROFILE_URI}/identity/v1/tenant/user`;
  return httpClient.post({ url, body: bodyData });
};

export const removeUser = (userName: any) => {
  const url = `${PROFILE_URI}/identity/v1/tenant/user/${userName}`;
  return httpClient.delete({ url });
};

export const getUser = (userName: any) => {
  const url = `${PROFILE_URI}/identity/v1/tenant/user/${userName}`;
  return httpClient.get({ url });
};

export const updateUser = (userName: any, bodyData: any) => {
  const url = `${PROFILE_URI}/identity/v1/tenant/user/${userName}`;
  return httpClient.put({ url, body: bodyData });
};

export const getAllCountry = () => {
  const url = `${COMMON_SERVICE_URI}/v1/countries`;
  return httpClient.get({ url });
};

export const getStateBasedOnCountry = (countryCode: any) => {
  let params = {
    'country-code': countryCode
  };
  const url = `${COMMON_SERVICE_URI}/v1/states`;
  return httpClient.get({ url, params });
};

export const getPinCode = (countryCode: any, stateCode: any) => {
  let params = {
    'country-code': countryCode,
    'state-code': stateCode
  };
  const url = `${COMMON_SERVICE_URI}/v1/postcodes`;
  return httpClient.get({ url, params });
};
